<template>



    <Modal :options="{width: '70vw'}" >

      <div class="title h2 text-white">Origen de datos</div>

      <div class="text-center my-4">
        <h5>Selecciona el origen de datos.</h5>
      </div>

      <div class="body">

        <div class="alert alert-primary mb-5" role="alert">
          <i class="fa fa-info-circle mr-sm-1"></i> <b>Importante:</b> La información cargada proviene de un origen externo
          los datos del origen deben ser compatibles con los del formulario para que puedan asignarse correctamente los valores.
          <br>
          <span class="font-italic">** Si considera que la infomación de los registros cargados no son correctos favor de validarlo en su origen de datos.</span>
        </div>

        <div class="col-12" v-if="!response_origen.length">
          <div class="alert alert-warning text-center w-100" role="alert">
            No se encontró información para cargar.
          </div>
        </div>

        <table v-else class="table table-sm table-striped table-bordered">
          <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col"></th>
            <th scope="col" v-for="col in cols" class="text-uppercase">{{col}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(row,index) in response_origen">
            <th scope="row">{{index+1}}</th>
            <th scope="row"><button class="btn btn-sm btn-primary" type="button" @click="seleccionar(row)">Seleccionar</button></th>
            <template v-for="col in cols">
              <td  v-if="row.valores_origen.hasOwnProperty(col)">{{row.valores_origen[col] ? row.valores_origen[col] : '-----'}}</td>
            </template>

            <th  v-for="campo_dinamico in response_origen[0].campos_dinamicos" v-if="campo_dinamico.activo" scope="row">{{ !campo_dinamico.valor_origen ? '' : campo_dinamico.valor_origen}}</th>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="footer mb-4">
        <div class="row justify-content-end">
          <div class="col-auto"><button class="btn btn-danger" @click="cancelar()"><i class="fa fa-window-close mr-sm-1"></i>Cancelar</button></div>
        </div>
      </div>

    </Modal>




</template>

<script>
import Modal from "@/components/Modal.vue";

export default {
  name: 'FormulatioSeleccionarOrigen',
  components: {Modal},
  props: {
    response_origen: {
      type: Array,
      default(){
        return []
      }
    }
  },
  data(){
    return {
      seleccion: null,
      cols: [],
    }
  },
  computed: {

  }
  ,mounted() {

    if (this.response_origen.length > 0) {
      this.cols = Object.keys(this.response_origen[0].valores_origen)

      for (const campo_dinamico of this.response_origen[0].campos_dinamicos) {
        if (campo_dinamico.activo)
          this.cols.push(campo_dinamico.nombre)
      }


    }
  }

  ,methods:{
    cancelar(){
      this.$emit('cancelar')
    },
    seleccionar(item){
      this.$emit('seleccion', item)
    }
  }
}
</script>

<style scoped>

</style>
